import { useEffect, useState } from 'react'
import { PreviewLabels } from './PreviewLabels'
import { DeleteBanner } from './DeleteBanner'
import { ILabel } from 'components/CreatePost/PreviewComponents/LabelsComponents/LabelsContentComponents/Label'
import { IPostVariant } from 'types/PostInterface'
import { deletePost, editPost } from '../iconConfig'
import { T } from 'utils'

interface Props {
  selectedIcon: string
  resetSelectedIcon: () => void
  post: IPostVariant
  labels: ILabel[]
  refreshPostLabels: (newLabels: ILabel[]) => void
}

export const ContentSelectedIcons = ({ selectedIcon, resetSelectedIcon, labels, post, refreshPostLabels }: Props) => {
 
  const [isVisible, setIsVisible] = useState(false)
 
  // Enabled actions
  const enabledActions = ['labels', 'delete', 'edit']

  // Handle animation visibility on icon selection change
  const handleAnimation = async () => {
    const visibility = enabledActions.includes(selectedIcon)
    setIsVisible(visibility)
  }
 
  useEffect(() => {
    handleAnimation()

    return () => setIsVisible(false)
  }, [selectedIcon])
 
  const handleSelectedIconToRender = () => {
    switch (selectedIcon) {
      case 'labels':
        return (
          <PreviewLabels
            postId={post._id!}
            className={isVisible ? 'selected_container' : ''}
            labels={labels}
            refreshPostLabels={refreshPostLabels}
          />
        )
      case 'delete':
        return (
          <DeleteBanner
            className={isVisible ? 'selected_container' : ''}
            resetSelectedIcon={resetSelectedIcon}
            text={T.postPage.confirmPostElimination}
            doAction={() => deletePost(post)}
          />
        )
      case 'edit':
        return (
          <DeleteBanner
            className={isVisible ? 'selected_container' : ''}
            resetSelectedIcon={resetSelectedIcon}
            text={T.postPage.confirmPostEdit}
            doAction={() => editPost(post)}
          />
        )

      default:
        return <></>
    }
  }

  return handleSelectedIconToRender()
}
