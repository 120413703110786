/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import Utils from 'api/Utils'
import { Box } from 'components'
import { useEffect, useState } from 'react'
import { IPostVariant, YTCategory } from 'types/PostInterface'
import { T } from 'utils'
import { updatePost } from 'utils/PostUtils/Post'
import ExtraSelect from '../GenericExtras/ExtraSelect/ExtraSelect'
import { ExtraTitle } from '../GenericExtras/ExtraTitle'

/* ------------------- INTERFACES  */

interface Props {
  post: IPostVariant
}

export const YoutubeCategory = ({ post }: Props) => {
  /* ----------------- STATE  */
  const [categories, setCategories] = useState<YTCategory[]>([])
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
  const [selectedCategory, setSelectedCategory] = useState<YTCategory | null>(post.data.category || null)

  /* ----------------- VARIABLES  */
  const { variantId } = post

  /* ----------------- METHODS  */
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev)
  }

  const handleSelectedCategory = (category: YTCategory) => {
    updatePost(variantId, {
      category: {
        id: category.id,
        title: category.title,
      },
    })
    setSelectedCategory(category)
  }

  /* ----------------- API CALL  */
  const fetchCategories = async () => {
    const request = await Utils.getYouTubeCategories('', post.info.account.account_id as string)
    const data = request.data as YTCategory[]

    setCategories(data)

    if (data && data.length > 0) {
      // Cerca la categoria "Notizie e politica" (id: "25")
      const newsCategory = data.find((category) => category.id === '25')

      // Se non esiste già una categoria nel post
      if (!post.data.category) {
        // Se esiste la categoria "Notizie e politica", usa quella, altrimenti usa la prima
        const categoryToUse = newsCategory || data[0]

        updatePost(variantId, {
          category: {
            id: categoryToUse.id,
            title: categoryToUse.title,
          },
        })

        setSelectedCategory(categoryToUse)
      } else {
        // Se esiste già una categoria selezionata nel post
        setSelectedCategory(post.data.category)
      }
    }
  }

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    fetchCategories()
  }, [])

  return (
    <YoutubeCategoryContainer>
      <ExtraTitle text={T.postPage.youtubeTubeExtra.category} />

      <ExtraSelect
        classes="select-category-youtube"
        selectWith="default"
        toggleDropdown={toggleDropdown}
        isDropdownOpen={isDropdownOpen}
        items={categories}
        dropdownHeaderText={selectedCategory ? selectedCategory.title : T.postPage.youtubeTubeExtra.selectCategory}
        onSelect={handleSelectedCategory}
        selectedItems={selectedCategory}
        truncateTextNum={30}
      />
    </YoutubeCategoryContainer>
  )
}

const YoutubeCategoryContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
`
