/* ------------------- IMPORTS */
import { Page } from 'components'
import { CreatePostContent } from 'components/CreatePost/CreatePostContent'
import PostNavigation from 'components/CreatePost/NavigationsBar/PostNavigation'
import { NavigationStructure, PageStructure } from 'components/UI'
import { useLocation } from 'react-router-dom'
import { TSection, TSubsection } from 'types/CreatePostInferfaces'

export const CreatePostPage = () => {
  const location = useLocation()
  const section = (location.pathname.split('/')[2] ?? 'preview') as TSection
  const subsection = (location.pathname.split('/')[3] ?? 'basic') as TSubsection

  return (
    <Page title="Post" checkPermission="publication">
      <PageStructure
        content={<CreatePostContent section={section} subsection={subsection} />}
        navigation={
          <NavigationStructure
            showNavigation={true}
            positionAbsolute={true}
            content={<PostNavigation section={section} subsection={subsection} />}
          />
        }
      />
    </Page>
  )
}
