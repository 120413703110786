/* ------------------- IMPORTS  */
import { ContentContainerStructure, ContentHeaderStructure, ContentStructure } from 'components/UI'
import { useEffect, useState } from 'react'
import { useAiGenerationStore } from 'stores/AiGenerationStore'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { TSection, TSubsection } from 'types/CreatePostInferfaces'
import { AppStore, T } from 'utils'
import {
  checkYTPreview,
  determineIsEmpty,
  getActiveInfo,
  getPreviewByVariant,
  getSocialsAccount,
} from 'utils/PostUtils/Post'
import {
  AI_TEXT_LINK_DEFAULT_HEIGHT,
  AI_TEXT_TEXT_DEFAULT_HEIGHT,
  EXTRAS_DEFAULT_WIDTH,
  MEDIAS_DEFAULT_WIDTH,
  PREVIEW_DEFAULT_HEIGHT,
  PREVIEW_DEFAULT_WIDTH,
  PREVIEW_INSTAGRAM_STORY_WIDTH,
  PREVIEW_LABELS_WIDTH,
  SECTIONS_BORDER_RADIUS,
  SECTIONS_PADDING,
  SOCIALS_DEFAULT_WIDTH,
} from '../../utils/Constants/CreatePostConstants'
import { ExtraContent } from './ExtraComponents/ExtraContent'
import { PostMediaContent } from './MediaComponents/PostMediaContent'
import { PostMediaHeader } from './MediaComponents/PostMediaHeader'
import { InstagramSwitchPreview } from './PreviewComponents/BasicComponents/BasicContentComponents/Previews/Instagram/InstagramSwitchPreview'
import { YoutubeSwitchPreview } from './PreviewComponents/BasicComponents/BasicContentComponents/Previews/Youtube/YoutubeSwitchPreview'
import { PreviewContent } from './PreviewComponents/PreviewContent'
import { PreviewHeader } from './PreviewComponents/PreviewHeader'
import { SocialHeader } from './SocialComponents/SocialHeader'
import { SocialsGridsContainer } from './SocialComponents/SocialsGridsContainer'
import { AiHeader } from './TextComponents/AiHeader'
import { TextPostContent } from './TextComponents/TextPostContent'

/* ------------------- INTERFACES  */
interface Props {
  section: TSection
  subsection: TSubsection
}

export const CreatePostContent = ({ section, subsection }: Props) => {
  /* ----------------- ZUSTAND  */
  //Generation store
  const { generatedByTextResults, generatedByLinkResults, selectedOption } = useAiGenerationStore((s) => ({
    generatedByTextResults: s.generatedByTextResults,
    generatedByLinkResults: s.generatedByLinkResults,
    selectedOption: s.selectedOption,
  }))

  //Post store
  const { posts, activePreview, activePost, globalPost, socialsAdded, uniqueVariants, activeMediaType } =
    useCreatePostStore((s) => ({
      posts: s.posts,
      activePreview: s.activePreview,
      activePost: s.activePost,
      globalPost: s.globalPost,
      socialsAdded: s.socialsAdded,
      uniqueVariants: s.uniqueVariants,
      activeMediaType: s.activeMediaType,
    }))

  const { setActivePost, setActivePreview, setActiveAccountId, setSocialsAdded } = useCreatePostStore.getState()

  /* ----------------- VARIABLES  */
  const isBrandVoiceSelected = selectedOption !== T.textGenerator.neutral

  /* ----------------- USESTATE  */
  // Valori state per determinare se il contenuto è vuoto o meno (valore di testo)
  const [isEmpty, setIsEmpty] = useState<boolean>(
    determineIsEmpty(subsection, generatedByTextResults, generatedByLinkResults)
  )
  const [emptyContainerValue, setEmptyContainerValue] = useState<number>(
    subsection === 'astersAiText'
      ? isBrandVoiceSelected
        ? AI_TEXT_TEXT_DEFAULT_HEIGHT - 60
        : AI_TEXT_TEXT_DEFAULT_HEIGHT
      : AI_TEXT_LINK_DEFAULT_HEIGHT
  )

  // Valore state per filtrare i socials
  const [textInputFilter, setTextInputFilter] = useState<string>('')

  /* ----------------- METHODS */
  // Metodo per posizionare lo switcher del post in simultanea
  const absoluteContent = () => {
    if (activePost === undefined) return null

    if ('_id' in activePost && activePost._id === 'GLOBAL') return

    if (['instagram'].includes(getActiveInfo().account.provider) && subsection === 'basic')
      return <InstagramSwitchPreview />

    if (['youtube'].includes(getActiveInfo().account.provider) && subsection === 'basic')
      return <YoutubeSwitchPreview />

    return null
  }

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    setIsEmpty(determineIsEmpty(subsection, generatedByTextResults, generatedByLinkResults))

    setEmptyContainerValue(
      subsection === 'astersAiText'
        ? isBrandVoiceSelected
          ? AI_TEXT_TEXT_DEFAULT_HEIGHT - 60
          : AI_TEXT_TEXT_DEFAULT_HEIGHT
        : AI_TEXT_LINK_DEFAULT_HEIGHT
    )
  }, [subsection, generatedByTextResults.length, generatedByLinkResults.length, selectedOption])

  // Controlla il cambio dei socialsAdded al cambio della lunghezza dei posts
  useEffect(() => {
    setSocialsAdded(getSocialsAccount())
  }, [posts.length])

  // UseEffect per gestire l'automatizzatone del setting active
  useEffect(() => {
    // Se c'è solo un post lo setta come active
    if (posts.length === 1) {
      setActivePost(posts[0])
      checkYTPreview(setActivePreview(getPreviewByVariant(posts[0], activeMediaType)))
      setActiveAccountId(posts[0].info.account.account_id)
    }

    // Se ci sono più post, setta di base il global
    if (posts.length === 0) {
      setActivePost(globalPost)
      setActivePreview(getPreviewByVariant(globalPost, activeMediaType))
      setActiveAccountId('GLOBAL')
    }

    if (posts.length > 1) {
      if (posts.every((p) => !p.data?.text && !p.data.medias?.length && !p.data.urls?.length)) {

        setActivePost(globalPost)
        setActivePreview(getPreviewByVariant(globalPost, activeMediaType))
        setActiveAccountId('GLOBAL')
      } else {
        const post = posts.at(-1)

        if (!post) return

        setActivePost(post)
        checkYTPreview(setActivePreview(getPreviewByVariant(post, activeMediaType)))
        setActiveAccountId(post.info.account.account_id)
      }
    }

  }, [socialsAdded.length])

  /* ----------------- ENUM  */
  const content = {
    preview: (
      <ContentStructure
        data-html2canvas-ignore="true"
        centered
        header={<ContentHeaderStructure content={<PreviewHeader subsection={subsection} />} />}
        absoluteFromContent={absoluteContent()}
        absoluteContainerWidth={
          subsection === 'basic' && activePreview === 'INSTAGRAM_STORY' ? PREVIEW_INSTAGRAM_STORY_WIDTH : '100%'
        }
        content={
          <ContentContainerStructure
            content={<PreviewContent subsection={subsection} />}
            borderRadius={SECTIONS_BORDER_RADIUS}
            bgColor={AppStore.theme.o.surface}
            height={PREVIEW_DEFAULT_HEIGHT}
            width={
              subsection === 'basic' && activePreview === 'INSTAGRAM_STORY' ? PREVIEW_INSTAGRAM_STORY_WIDTH : '100%'
            }
          />
        }
        width={subsection === 'labels' ? PREVIEW_LABELS_WIDTH : PREVIEW_DEFAULT_WIDTH}
        height={subsection === 'labels' ? 'auto' : '100%'}
      />
    ),
    text: (
      <ContentStructure
        header={
          subsection !== 'templates' ? <ContentHeaderStructure content={<AiHeader subsection={subsection} />} /> : false
        }
        content={
          <ContentContainerStructure
            content={<TextPostContent subsection={subsection} />}
            dynamicContainer={
              subsection !== 'templates'
                ? {
                  isEmpty,
                  fromTopEmpty: 0,
                  fromTopFull: 0,
                  containerEmpty: emptyContainerValue,
                  containerFull: '100%',
                  transition: '.4s ease .3s',
                }
                : undefined
            }
            padding={SECTIONS_PADDING}
            borderRadius={SECTIONS_BORDER_RADIUS}
            bgColor={AppStore.theme.o.surface}
          />
        }
        width={PREVIEW_DEFAULT_WIDTH}
      />
    ),
    media: (
      <ContentStructure
        header={<ContentHeaderStructure content={<PostMediaHeader />} />}
        content={
          <ContentContainerStructure
            content={<PostMediaContent section={section} />}
            bgColor={AppStore.theme.o.surface}
            borderRadius={SECTIONS_BORDER_RADIUS}
          />
        }
        width={MEDIAS_DEFAULT_WIDTH}
      />
    ),
    extra: (
      <ContentStructure
        content={
          <ContentContainerStructure
            content={<ExtraContent />}
            bgColor={AppStore.theme.o.surface}
            borderRadius={SECTIONS_BORDER_RADIUS}
          />
        }
        width={EXTRAS_DEFAULT_WIDTH}
      />
    ),
    socials: (
      <ContentStructure
        header={
          <ContentHeaderStructure
            content={<SocialHeader textInputFilter={textInputFilter} setTextInputFilter={setTextInputFilter} />}
          />
        }
        content={
          <ContentContainerStructure
            content={<SocialsGridsContainer textInputFilter={textInputFilter} />}
            bgColor={AppStore.theme.o.surface}
            borderRadius={SECTIONS_BORDER_RADIUS}
          />
        }
        width={SOCIALS_DEFAULT_WIDTH}
      />
    ),
  }

  return content[section]
}
