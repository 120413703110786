/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { BackwardIcon, Box, ForwardIcon, Icons, Text } from 'components'
import { useEffect, useRef, useState } from 'react'
import { getSelectedMedias } from 'utils/PostUtils/MediaPost'
import { UploadMedia } from './UploadMedia'
import { getActiveInfo, nextMedia, prevMedia } from 'utils/PostUtils/Post'
import { AppStore, T } from 'utils'
import { canShowMetadata, findVisibleMetadata } from 'utils/PostUtils/ShortUrl'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { truncateText } from 'pages/ai-discover/commonFunction'
import { IPostVariant } from 'types/PostInterface'
import play from 'assets/icons/asters-play-video.svg'
import pause from 'assets/icons/asters-pause-video.svg'

/* ------------------- INTERFACES  */
interface Props {
  width?: number | string
  height: number | string
  arrowsPositions: number
  postPreview?: IPostVariant
  mediaIndex?: number
  setMediaIndex?: (index: number) => void
}

export const MediaContainerPost = ({
  width,
  height,
  arrowsPositions,
  postPreview,
  mediaIndex,
  setMediaIndex,
}: Props) => {
  /* ----------------- ZUSTAND  */
  const { activePost } = useCreatePostStore((s) => ({
    activePost: s.activePost as any,
  }))

  const { setActiveMediaType, posts } = useCreatePostStore()

  /* ----------------- STATE  */
  const [index, setIndex] = useState<number>(0)

  const i = mediaIndex ?? index

  /* ----------------- METHODS  */

  /* ----------------- VARIABLES  */
  const post = postPreview ?? activePost

  const medias = getSelectedMedias(post)

  const workingMedia = medias[i]
  const typeOfMedia = workingMedia?.metadata?.fileType

  const firstMetadata = findVisibleMetadata(post)
  const visibleLink = firstMetadata ? firstMetadata[0] : undefined

  const showMetadata = canShowMetadata(undefined, post)

  const activeProvider = getActiveInfo(post).account?.provider

  const isXActive = activeProvider === 'x'
  const isInstagramActive = activeProvider === 'instagram'
  
  const [videoButtonIcon, setVideoButtonIcon] = useState<string>(play)
  const videoRef = useRef<HTMLVideoElement>(null)

  const playPauseVideo = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setVideoButtonIcon(pause)
      } else {
        videoRef.current.pause();
        setVideoButtonIcon(play)
      }
    }
  };

  useEffect(() => {
    !postPreview && setActiveMediaType(typeOfMedia)
  }, [workingMedia])

  return (
    <MediaContainerPostContainer style={{ width: width ?? '100%', height: height }}>
      {showMetadata && (
        <>
          <MetadataImg
            className="metadata-img"
            src={visibleLink?.metaData.ogImg}
            height={isXActive ? '100%' : '294px'}
            style={{
              borderRadius: isXActive ? '14px' : '14px 14px 0px 0px',
            }}
          />

          {!isXActive && (
            <>
              <MetadataTitle>
                {visibleLink?.metaData?.ogTitle?.length && visibleLink?.metaData?.ogTitle?.length > 80 ? (
                  <>
                    {visibleLink?.metaData?.ogTitle?.slice(0, 80)} <SeeMoreText>... {T.postPage.more}</SeeMoreText>
                  </>
                ) : (
                  <>{visibleLink?.metaData?.ogTitle}</>
                )}
              </MetadataTitle>
              <MetadataDescription>
                {visibleLink?.metaData?.ogDescription?.length && visibleLink?.metaData?.ogDescription?.length > 80 ? (
                  <>
                    {visibleLink?.metaData?.ogDescription?.slice(0, 80)}{' '}
                    <SeeMoreText>... {T.postPage.more}</SeeMoreText>
                  </>
                ) : (
                  <>{visibleLink?.metaData?.ogDescription}</>
                )}
              </MetadataDescription>
            </>
          )}

          {isXActive && <MetadataTitleX>{truncateText(visibleLink?.metaData?.ogTitle, 40)}</MetadataTitleX>}
        </>
      )}

      {/* Empty state media */}
      {posts.length > 0 && !postPreview && !showMetadata && medias?.length === 0 && <UploadMedia />}

      {/* Empty state media quando siamo dentro la postPreview, ossia dentro la modale in calendar/bozze*/}
      {isInstagramActive && postPreview && !showMetadata && medias?.length === 0 && (
        <Box center height={'100%'}>
          <Icons.medias fill={AppStore.theme.o.darkGrey} width={90} height={90} />

          <Text weight={600} color={AppStore.theme.o.darkGrey}>
            {T.postPage.noMediaInserted}
          </Text>
        </Box>
      )}

      {/* Diverso dai video  */}
      {!showMetadata && medias?.length > 0 && !workingMedia?.mimetype?.includes('video') && (
        <MediaImg src={workingMedia?.url} />
      )}

      {/* Video */}
      {!showMetadata && medias?.length > 0 && workingMedia?.mimetype?.includes('video') && (
        <div style={{position: 'relative', overflow: 'hidden'}}>
          <MediaVideo ref={videoRef} muted preload='metadata' src={workingMedia?.url} />
          <PlayPauseButton onClick={playPauseVideo}>
            <img style={{ height: '32px' }} src={videoButtonIcon} alt="play button" />
          </PlayPauseButton>
        </div>
      )}

      {/* Carousel controls */}
      {!showMetadata && medias?.length > 1 && (
        <ContainerArrow>
          <BackwardIcon
            onClick={() => prevMedia(i, setMediaIndex ?? setIndex, medias)}
            style={{ position: 'relative', left: `-${arrowsPositions}px` }}
          />
          <ForwardIcon
            onClick={() => nextMedia(i, setMediaIndex ?? setIndex, medias)}
            style={{ position: 'relative', right: `-${arrowsPositions}px` }}
          />
        </ContainerArrow>
      )}
    </MediaContainerPostContainer>
  )
}

const MediaContainerPostContainer = styled(Box)`
  margin: 0 auto;
  position: relative;
  border: 1px solid ${() => AppStore.theme.o.grey};
`

const MediaImg = styled.img`
  object-fit: cover;
  min-width: -webkit-fill-available;
  border-radius: 14px;
  width: 100%;
  height: 100%;
`

const MediaVideo = styled.video`
  object-fit: cover;
  min-width: -webkit-fill-available;
  border-radius: 14px;
  width: 100%;
  height: 100%;
  &::-webkit-media-controls-panel {
    display: none !important;
    opacity: 1 !important;
  }
`
const PlayPauseButton = styled.button`
  position: absolute;
  top: 50%; 
  left: 50%;
  padding: 8px;
  transform: translate(-50%, -50%);
  border-radius: 14px;
  border: solid 1px #00747C;
  background: #EAFCFE;
  z-index: 10;
  &:hover {
   cursor: pointer;
  }
`

const ContainerArrow = styled(Box)`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const MetadataImg = styled.img`
  width: 100%;
`

const MetadataDescription = styled(Box)`
  padding: 8px 16px;
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: Aktiv Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
`

const MetadataTitle = styled(Box)`
  padding: 0px 16px 4px;
  color: ${() => AppStore.theme.o.black};
  font-family: Aktiv Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
`

const SeeMoreText = styled.span`
  margin-left: 4px;
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.002px;
`

const MetadataTitleX = styled(Box)`
  position: absolute;
  bottom: 10px;
  left: 13px;
  color: ${() => AppStore.theme.o.black};
  font-family: Aktiv Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 4px 8px;
  background-color: ${() => AppStore.theme.o.lightestGrey};
`
