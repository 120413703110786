import Medias from 'api/Medias'
import React, { useEffect } from 'react'
import { AppStore, T, showSuccess } from 'utils'
import background from './../assets/BackEdit.jpeg'
import { Button } from './Button'
import { Icons } from './Icons'
import { Text } from './Text'

export function isBriaEvent(event: Event): event is BriaPostMessage {
  return (
    typeof event === "object" &&
    event !== null &&
    "data" in event &&
    typeof (event as BriaPostMessage).data === "object" &&
    (event as BriaPostMessage).data !== null &&
    "type" in (event as BriaPostMessage).data &&
    typeof (event as BriaPostMessage).data.type === "string" &&
    (event as BriaPostMessage).data.type.startsWith("Bria")
  );
}

export type BriaPostMessage = Event & {
  data: {
    iframeId: string,
    sessionId: string,
    type: "Bria_Action",
    message: {
      page: string,
      action: string,
      type: string,
      items:
      {
        type: string,
        src: string
      }[]

    }
  }
}

interface Props {
  onFileChange: (e) => void
  onOpenBriaModal: (imageUrl?: string) => void
}

export const HeaderBriaButton = ({ onFileChange, onOpenBriaModal }: Props) => {
  const [currentUserCredentials, setCurrentUserCredentials] = React.useState<any>(null)


  const onBriaMessage = (event: BriaPostMessage) => {

    if (isBriaEvent(event)) {

      if (event.data.message.action === 'save') {
        console.log('event message is of type save')
        console.log(`event message items n=${event.data.message.items.length}`)
        Promise.all(event.data.message.items.map((image) => {
          return Medias.urlToFile(image.src, `generated_${+new Date()}.png`)
        })).then(files => {
          console.log('generated files from ulrs', files)
          onFileChange({ target: { files } })
          showSuccess('Image generated successfully')
          
        })
      }

    }
  }

  useEffect(() => {
    if (AppStore.loggedUser) {
      const fullName = AppStore.loggedUser.name
      const firstName = fullName.split(' ')[0]
      const lastName = fullName.split(' ')?.[1] ?? ''
      setCurrentUserCredentials({
        email: AppStore.loggedUser.email,
        firstName: firstName,
        lastName: lastName,
      })
    }
    window.addEventListener(
      "message",
      onBriaMessage,
      false
    );

    return () => {window.removeEventListener('message', onBriaMessage)}
  }, [])


  return (
    <Button
      variant='neutral'
      onClick={() => onOpenBriaModal()}
      ml={25}
      ph={48}
      size="small"
      style={{
        backgroundImage: `url(${background})`,
        objectFit: 'scale-down',
        backgroundSize: '110%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
      hideBoxShadowOnHover
      preventLoading
    >
      <Icons.imgFolderMark style={{ marginRight: 4, width: 38, height: 38 }} />
      <Text style={{ marginRight: 8, color: 'white' }} weight={700}>{T.header.imageGen}</Text>
    </Button>
  )
}
