import ShortUrl from 'api/ShortUrl'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { IPostData, IPostVariant, IUrl } from 'types/PostInterface'
import { AppStore, T, showWarning } from 'utils'
import { DEFAULT_URL, WHITELIST_URL_FUNCTIONALITIES_PROVIDERS } from 'utils/Constants/CreatePostConstants'
import {
  getActiveDescription,
  getActiveInfo,
  getActiveText,
  getFirstCommentText,
  getPostData,
  updateActive,
  updatePost,
} from './Post'
import { json } from 'stream/consumers'

export const findFirstDiff = (string, string2) => {
  const stringWords = string
  const string2Words = string2

  let index = 0
  for (const word of string2Words) {
    if (word !== stringWords[index]) {
      let diff = ''
      for (let i = index; i < string2Words.length; i++) {
        diff += string2Words[i]
        if (string2Words[i] === ' ' || string2Words[i] === '\n') {
          break
        }
      }
      return diff
    }
    index++
  }
  return ''
}

export interface PasteLinkConfig {
  activePost: IPostVariant | IPostData
  originalUrl?: string
  newText: string
  finalText?: string
  firstComment?: boolean
  isDescription?: boolean
}

export const onPasteLink = async (config: PasteLinkConfig) => {
  const { activePost, newText, firstComment, isDescription, finalText } = config

  useCreatePostStore.setState({ textLoading: true })

  if (activePost === undefined) return

  const postData = getPostData(activePost)

  // Check if the text is for the first comment
  const isForFirstComment = firstComment

  // Define the text to evaluate
  const evaluateText = isForFirstComment ? postData.firstComment?.text : postData.text

  // Define the origin URL
  const originUrl = newText

  try {
    // ----------- If it's not a shortened link -----------
    const activePostSocialAccount = AppStore.socialAccounts.find(
      (account) => account._id === (activePost as IPostVariant)?.info?.account.account_id
    )

    console.log(`onPasteLink => postData.urls=${postData.urls?.map(u=> u.originalUrl)}`)
    console.log(`onPasteLink => postData.shorturls=${postData.urls?.map(u=> u.shortUrl)}`)

    const isShortLink = (postData?.urls?.map(u => u.shortUrl) || []).indexOf(originUrl) > -1

    console.log(`onPasteLink; originUrl=${originUrl} isShortLink=${isShortLink}`)


    if (isShortLink) {
      console.log(`this url=${originUrl} is a short link, skipping`)
      useCreatePostStore.setState({ textLoading: false })
      return
    }

    const processedUrl = purgeParamsAddRef(originUrl, activePostSocialAccount?.refParameter);
    console.log('onPasteLink->processedUrl:', processedUrl)

    const newUrlProperty: any = await recoverShortenedLink(
      processedUrl,
      'shorten',
      activePostSocialAccount?.refParameter,
      activePostSocialAccount?.customShorturlDomain
    )

    console.log('onPasteLink -> recoverShortenedLink ->', newUrlProperty)

    if (!newUrlProperty) {
      console.log('onPasteLink->No short-link recovered, exiting the function')
      useCreatePostStore.setState({ textLoading: false })
      return
    }
    
    const textUrl = (postData?.urls?.find(u => u.originalUrl === originUrl) );

    if (textUrl && !textUrl.shortened) {
      console.log('onPasteLink->The url should remain expanded, exiting the function')
      useCreatePostStore.setState({ textLoading: false })
      return
    }

    const updatedCopyDescription = (finalText ?? evaluateText).replaceAll(originUrl, newUrlProperty?.shortUrl)

    const urls = isForFirstComment ? postData.firstComment?.urls : postData.urls

    // Map the urls and set the metadata to visible false
    const updateVisibilyMetadatas =
      urls?.length > 0
        ? urls?.map((url) => ({
          ...url,
          metaData: { ...url.metaData, visible: url.metaData.visible ?? false },
        }))
        : []

    if (!isForFirstComment || !('variantId' in activePost)) {
      const updateValue = !isDescription ? { text: updatedCopyDescription } : { description: updatedCopyDescription }

      // leave only one urls for originalUrl or shortUrl
      const urlss = removeDuplicateUrls([...updateVisibilyMetadatas, newUrlProperty])

      console.log('onPasteLink -> not 1st comm or ! variant; removed duplicate urls', urlss)

      updateActive({ ...updateValue, urls: urlss, from: 'onPasteLink' })
    } else {
      const urlss = removeDuplicateUrls([...updateVisibilyMetadatas, newUrlProperty])


      console.log('onPasteLink -> not 1st comm or ! variant; removed duplicate urls', urlss)
      updatePost(activePost.variantId, {
        firstComment: { text: updatedCopyDescription, urls: urlss },
      })
    }
  } catch (error) {
    console.error('error adding ref parameter to the url', error)
  }

  useCreatePostStore.setState({ textLoading: false })
}

function removeDuplicateUrls(urls: IUrl[]): IUrl[] {
  console.log(`Removeing duplicate urls from=${urls.map((o) => o.originalUrl)}`)

  // group shorturls by original url
  const shorturlsByUrl = urls.reduce((acc, url) => {
    const slug = new URL(url.originalUrl)
    slug.search = ''

    return { ...acc, [slug.toString()]: [...acc[url.originalUrl] || [], url.shortUrl] }
  }, {} as Record<string, string[]>)

  // for the same original url, only take the last shorturl generated
  urls = urls.filter((url) => {
    const slug = new URL(url.originalUrl);
    slug.search = ''

    return shorturlsByUrl[slug.toString()].at(-1) === url.shortUrl
  })


  const uniqueUrls = new Map<string, IUrl>()

  console.log('removeDuplicateUrls', urls)

  for (const url of urls) {

    // Usa sia originalUrl che shortUrl come chiavi per controllare i duplicati
    const key = url.originalUrl || url.shortUrl

    if (!uniqueUrls.has(key)) {
      uniqueUrls.set(key, url)
    }
  }

  return Array.from(uniqueUrls.values())
}

export const recoverShortenedLink = async (originUrl: string, type: 'shorten' | 'standard', ref?: string, domain?: string) => {
  try {
    const results = await ShortUrl.getShortLinkAndMetadata(originUrl, type, ref)
    if (results === undefined) return showWarning(T.warning.notValidUrl)

    if (domain) {
      results.shortUrl = results.shortUrl.replace('aste.rs', domain)
    }

    const utmConfig = {
      utm_source: '',
      utm_medium: '',
      utm_campaign: '',
      utm_term: '',
      utm_content: '',
    }

    const metaData = {
      ogUrl: results?.metaData?.ogUrl,
      ogSiteName: results?.metaData?.ogSiteName,
      ogTitle: results?.metaData?.ogTitle,
      ogDescription: results?.metaData?.ogDescription,
      ogImg: results?.metaData?.ogImg,
      visible: true,
    }

    const newUrlProperty: IUrl = {
      id: results._id,
      shortUrl: results.shortUrl,
      originalUrl: results.origUrl,
      shortened: true,
      utmConfig,
      metaData,
    }

    return newUrlProperty
  } catch (error) {
    console.error('error adding ref parameter to the url', error)
  }
}

// Ritorna il primo metadata visible
export const findVisibleMetadata = (post: IPostData | IPostVariant, firstComment?: boolean): any => {
  if (post === undefined) return

  if (firstComment === undefined) {
    if ('_id' in post && post._id === 'GLOBAL')
      return (post as IPostData).urls?.filter((url) => url?.metaData?.visible ?? true)
    if ('variantId' in post) return post.data.urls?.filter((url) => url?.metaData?.visible ?? true)
  }

  if (firstComment === true) {
    if ('variantId' in post) return post.data.firstComment?.urls?.filter((url) => url?.metaData?.visible ?? true)
  }

  return []
}

// Di base ritorna l'ultimo url che trova
export const findWorkingUTM = (post: IPostData | IPostVariant, selctedIndex: number, firstComment?: boolean) => {
  if (firstComment === undefined) {
    if ('variantId' in post) return post.data.urls && post.data.urls[selctedIndex]
    if ('_id' in post && post._id === 'GLOBAL') return post.urls && post.urls[selctedIndex]
  }

  if (firstComment === true) {
    if ('variantId' in post) return post.data.firstComment?.urls && post.data.firstComment?.urls[selctedIndex]
  }

  return DEFAULT_URL
}

// Ritorna link completo con i valori utm
export const newUtmUrl = (visibleLink: IUrl, utmValues: any) => {
  try {
    const origUrl = visibleLink.originalUrl ?? ''

    // check if url already has utm params then replace them with the key
    const url = new URL(origUrl)
    const params = new URLSearchParams(url.search)
    const keys = Object.keys(utmValues)
    keys.forEach((key) => {
      params.delete(key)
    })

    // add new params
    keys.forEach((key) => {
      if (utmValues[key] && utmValues[key] !== '') {
        params.append(key, utmValues[key])
      }
    })
    url.search = params.toString()
    return url.toString()
  } catch (error) {
    return visibleLink?.originalUrl ?? ''
  }
}

export const converUtmConfig = (url: IUrl) => {
  return {
    utm_source: url?.utmConfig?.utm_source,
    utm_medium: url?.utmConfig?.utm_medium,
    utm_campaign: url?.utmConfig?.utm_campaign,
    utm_term: url?.utmConfig?.utm_term,
    utm_content: url?.utmConfig?.utm_content,
  }
}

//@refactoring da fare
export const toggleLinkShortening = (
  post: IPostData | IPostVariant,
  shorten: boolean,
  visibleLink: IUrl,
  firstComment?: boolean,
  isDescription?: boolean
) => {
  if (firstComment === undefined) {
    if ('_id' in post && post._id === 'GLOBAL') {
      const newUrls = (post as IPostData).urls?.map((url) => {
        if (url.id === visibleLink.id) {
          return { ...url, shortened: shorten }
        }
        return url
      })

      //@refactoring da fare
      const currentText = !isDescription ? getActiveText() : getActiveDescription()
      console.log('toggleLinkShortening', visibleLink, currentText)

      const valueToReplace = visibleLink.shortened ? visibleLink.shortUrl : visibleLink.originalUrl
      const newValue = visibleLink.shortened ? visibleLink.originalUrl : visibleLink.shortUrl

      const editedText = currentText?.replaceAll(valueToReplace, newValue)
      const updateValue = !isDescription ? { text: editedText } : { description: editedText }
      updateActive({ ...updateValue, urls: newUrls, from: 'setShortenedLink' })
    }

    if ('variantId' in post) {

      const newUrls = post.data.urls?.map((url) => {
        if (url.id === visibleLink.id) {
          return { ...url, shortened: shorten }
        }
        return url
      })
      console.log(`toggleLinkShortening, Shortening="${shorten}"`, visibleLink, post.data.urls?.map(u => u.originalUrl))

      const currentText = !isDescription ? getActiveText() : getActiveDescription()
      const valueToReplace = visibleLink.shortened ? visibleLink.shortUrl : visibleLink.originalUrl
      const newValue = visibleLink.shortened ? visibleLink.originalUrl : visibleLink.shortUrl
      const editedText = currentText?.replaceAll(valueToReplace, newValue)
      const updateValue = !isDescription ? { text: editedText } : { description: editedText }


      updateActive({ ...updateValue, urls: newUrls, from: 'setShortenedLink' })
    }
  }

  if (firstComment === true) {
    if ('variantId' in post) {
      const newUrls = post.data.firstComment?.urls?.map((url) => {
        if (url.id === visibleLink.id) {
          return { ...url, shortened: shorten }
        }
        return url
      })

      const actualLinkValue = visibleLink.shortened
        ? visibleLink.shortUrl
        : newUtmUrl(visibleLink, converUtmConfig(visibleLink))

      const newText = visibleLink.shortened
        ? getFirstCommentText(post)?.replaceAll(actualLinkValue, newUtmUrl(visibleLink, converUtmConfig(visibleLink)))
        : getFirstCommentText(post)?.replaceAll(actualLinkValue, visibleLink.shortUrl)

      updatePost(post.variantId, { firstComment: { text: newText, urls: newUrls } })
    }
  }
}

export const getLinkProperties = (post?: IPostVariant) => {
  if (post === undefined) {
    return getActiveLinkProperties()
  }
  if (post !== undefined) {
    return getLinkPropertiesFirstComment(post)
  }
}

export const getActiveLinkProperties = () => {
  const { activePost } = useCreatePostStore.getState()

  if (activePost === undefined) return []

  if ('_id' in activePost && activePost._id === 'GLOBAL') return (activePost as IPostData).urls ?? []

  if ('variantId' in activePost) return activePost.data.urls ?? []

  return []
}

export const getLinkPropertiesFirstComment = (post: IPostVariant) => {
  return post.data.firstComment?.urls ?? []
}

// Il parametro "post" viene passato solo quando si lavora in un post specifico
// nello specifico quando lavoriamo nel primo commento, altrimenti se si lavora
// nella sezione di preview si utilizza il post attivo
export const canShowMetadata = (post?: IPostVariant, previewPost?) => {
  if (post !== undefined) {
    const firstMetadata = findVisibleMetadata(post, true)
    const visibleLink = firstMetadata ? firstMetadata[0] : undefined

    return (
      post?.info?.account &&
      WHITELIST_URL_FUNCTIONALITIES_PROVIDERS?.includes(post.info.account.provider) &&
      visibleLink &&
      visibleLink.metaData.ogTitle !== undefined
    )
  }

  if (!post) {
    const { activePost } = useCreatePostStore.getState()

    const workingPost = previewPost ?? activePost

    const firstMetadata = findVisibleMetadata(workingPost)
    const visibleLink = firstMetadata ? firstMetadata[0] : undefined

    return (
      getActiveInfo(workingPost).account &&
      WHITELIST_URL_FUNCTIONALITIES_PROVIDERS.includes(getActiveInfo(workingPost).account.provider) &&
      visibleLink &&
      visibleLink.metaData.ogTitle !== undefined
    )
  }
}

export const purgeParamsAddRef = (url: string, ref: string | null | undefined): string => {
  const urlObject = new URL(url)
  // remove all query Params from url
  urlObject.search = ''

  // add refParam
  if (ref) urlObject.searchParams.append('ref', ref)

  return urlObject.toString();
}

export const replaceParamsWithRefForAllUrls = (description: string, refParameter?: string, urlRegex?: RegExp): string => {
  console.log(`Replacing urls=${urlRegex} in description="${description}" by removing params and adding ref param=${refParameter}`)
  return description.replaceAll(urlRegex!, (url) => {

    try {

      return purgeParamsAddRef(url, refParameter)

    } catch (e) {

      console.error(`There was some problem processing url="${url}"`)
      return url

    }
  })
}

export const filterUrl = (postUrls: IUrl[], urls: string[]): string[] => {
  console.log('filterUrls', postUrls, urls)
  return urls.filter((url) => postUrls
    .every((postUrl) =>
      !postUrl.originalUrl.startsWith(url) &&
      !postUrl.shortUrl.startsWith(url))
  )
}

export const getRefParameter = (_id: string): [string, string] => {
  const socialAccount = AppStore.socialAccounts.find((account) => account._id === _id)
  return [socialAccount?.refParameter ?? '', socialAccount?.customShorturlDomain ?? '']
}

export const checkIfUrlAlreadyExists = (storedUrls: IUrl[], urls: string[]): boolean => {
  return urls.every((url) => storedUrls.some((storedUrl) => url.startsWith(storedUrl.originalUrl)))
}

export const manageGlobalLinkUpdate = async (
  contentUrls: string[],
  posts: IPostVariant[],
  urlRegex: RegExp
): Promise<IPostVariant[]> => {

  // If user is editing a global post and has social accounts added cycle through all
  // the social accounts and add the ref parameter to the links
  const { activeAccountId } = useCreatePostStore.getState()

  if (activeAccountId !== 'GLOBAL') return posts

  const updatedPosts: IPostVariant[] = []

  await Promise.all(
    posts.map(async (post, index) => {

      // Get the ref parameter from the social account and start adding it to the urls
      const [ref, customDomain] = getRefParameter(post?.info?.account?.account_id)

      const nonShortenedUrls = contentUrls.filter(u => !u.includes(customDomain || 'aste.rs'))

      // Get all the urls in the text that are not in the post datas
      const newUrls =
        !!post.data?.urls && post.data.urls.length > 0 ? filterUrl(post.data.urls, nonShortenedUrls) : nonShortenedUrls

      // If all urls are already in the post return
      if (newUrls.length === 0) {
        updatedPosts.push(post)
        return
      }


      const urlsToSave: IUrl[] = []

      await Promise.all(
        newUrls.map(async (url) => {
          try {
            const res = (await recoverShortenedLink(url, 'standard', ref, customDomain)) as IUrl
            console.log('newUrl', res)
            res.shortUrl = 'www.google.com'
            urlsToSave.push(res)
          } catch (error) {
            console.error('error adding ref parameter to the url', error)
          }
        })
      )

      console.log('urlsToSave', urlsToSave?.[0].originalUrl)

      const urls = [...(post?.data?.urls ?? []), ...urlsToSave]

      // Save the new urls in the post
      if (index === 0) {
        updateActive({ urls })
      }

      const newText = post.data.text

      // Update the post data
      updatedPosts.push({
        ...post,
        data: { ...post.data, text: newText, urls: [...(post.data.urls ?? []), ...urlsToSave] },
      })
    })
  )
  return updatedPosts
}

export const manageVariantLinkUpdate = async (
  contentUrls: string[],
  activePost: IPostVariant,
  urlRegex: RegExp,
  description: string
): Promise<void> => {
  if (contentUrls.length === activePost.data.urls?.length ?? 0) return

  // Get the ref parameter from the social account and start adding it to the urls
  const [ref, customDomain] = getRefParameter(activePost?.info?.account?.account_id)

  const nonShortenedUrls = contentUrls.filter(u => !u.includes(customDomain || 'aste.rs'))

  // Get all the urls in the text that are not in the post datas
  const newUrls =
    !!activePost.data?.urls && activePost.data.urls.length > 0 ? filterUrl(activePost.data.urls, nonShortenedUrls) : nonShortenedUrls


  if (newUrls.length === 0) return

  // add the ref parameter to the urls
  try {
    const newLinks: IUrl[] = []
    newUrls.length > 0 &&
      (await Promise.all(
        newUrls.map(async (url) => {
          try {
            const response = (await recoverShortenedLink(url, 'standard', ref, customDomain)) as IUrl
            newLinks.push(response)
          } catch (error) {
            console.error('error adding ref parameter to the url', error)
          }
        })
      ))

    // Update description with the new urls
    const newDescription = replaceParamsWithRefForAllUrls(description, ref, urlRegex)

    // update the post in the store
    updateActive({
      text: newDescription,
      urls: [...(activePost?.data?.urls || []).filter((u) => contentUrls.includes(u.originalUrl)), ...newLinks],
    })
    return
  } catch (error) {
    console.error('error adding ref parameter to the url', error)
  }
  return
}
