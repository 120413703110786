import { IFilter } from 'types'
import { T } from 'utils'

export const socialFilters: IFilter[] = [
  { key: 'facebook', name: T.common.socials.facebook, icon: 'facebook' },
  { key: 'instagram', name: T.common.socials.instagram, icon: 'instagram' },
  // { key: 'x', name: T.common.socials.xTwitter, icon: 'x' },
  { key: 'linkedin', name: T.common.socials.linkedin, icon: 'linkedin' },
  { key: 'tiktok', name: T.common.socials.tiktok, icon: 'tiktok' },
  { key: 'youtube', name: T.common.socials.youtube, icon: 'youtube' },
]

export type TSocialFilter = 'all' | 'instagram' | 'facebook' | 'youtube' | 'tiktok' | 'linkedin' | 'x'
